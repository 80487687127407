<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <f-container topBottom>
      <f-form />
    </f-container>
  </div>
</template>

<script>
import FForm from '../../components/Profile/PersonalDataForm.vue';

export default {
  components: {
    FForm,
  },
  computed: {
    prevLinks() {
      return [
        {
          title: this.$t('labels.profile'),
          route: {
            name: 'profile-view',
          },
        },
      ];
    },
    title() {
      return this.$t('labels.personalData');
    },
  },
  beforeDestroy() {
    // this.$store.dispatch('setting/clear', ['dataValues']);
  },
};
</script>
