<template>
  <div>
    <v-row>
      <v-col cols="12">
        <label class="image-holder">
          <img
            v-if="currentAvatar"
            :src="currentAvatar"
            class="image-holder__image"
          />
          <v-icon v-if="!currentAvatar" dark class="image-holder__icon">
            mdi-account-circle
          </v-icon>
          <input
            class="image-holder__input"
            type="file"
            @change.prevent="onChangeAvatar"
          />
        </label>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import userUtils from '../../helpers/user_utils';
import fileUtils from '../../helpers/file_utils';

export default {
  data: () => ({
    preview: null,
  }),
  computed: {
    user() {
      return this.$store.state.user.current;
    },
    currentAvatar() {
      const avatarURL = this.user.avatar
        ? userUtils.avatarURL(this.user.avatar)
        : null;
      return this.preview || avatarURL;
    },
  },
  methods: {
    async onChangeAvatar(e) {
      const file = e.target.files[0];
      this.user.avatar = file;
      this.preview = await fileUtils.fileToBase64(file);
    },
  },
};
</script>

<style lang="scss">
.image-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(148, 148, 148);
  width: 150px;
  height: 150px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 18px;
  margin-top: -10px;

  &__image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  &__input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }
}
</style>
