<template>
  <v-row>
    <v-col cols="12" sm="12" md="4">
      <v-select
        v-model="user.country"
        name="user-country"
        item-value="_id"
        item-text="name"
        :items="countriesList"
        :label="$tt.capitalize($t('labels.country'))"
        :rules="valid"
        required
        @change="
          getStates();
          setDialCode();
        "
        outlined
        :disabled="isLoading"
      />
    </v-col>
    <v-col cols="12" sm="12" md="4">
      <v-select
        v-model="user.state"
        name="user-state"
        :items="states"
        :label="$tt.capitalize($t('labels.states'))"
        :rules="belongsToBrazil()"
        @change="getCities"
        outlined
        :disabled="isLoading"
      />
    </v-col>
    <v-col cols="12" sm="12" md="4">
      <v-autocomplete
        v-model="user.city"
        outlined
        name="user-city"
        :disabled="isLoading"
        :items="cities"
        :label="$tt.capitalize($t('labels.city'))"
      />
    </v-col>
  </v-row>
</template>

<script>
import validatorRules from '../../helpers/validator_rules';

export default {
  data: () => ({
    states: [],
    cities: [],
    brazilID: '',
  }),
  computed: {
    user() {
      return this.$store.getters['user/current'];
    },
    countriesList() {
      const countries = this.$store.state.country.list;
      if (typeof countries.count != 'undefined' && countries.count > 0) {
        var list = countries.items.map(country => {
          return {
            _id: country._id,
            name:
              country.name[this.$i18n.locale] == 'NULL'
                ? country.name['en']
                : country.name[this.$i18n.locale],
          };
        });
        return list;
      }
    },
    valid() {
      return validatorRules.required(this.$t('rules.required'));
    },
  },
  async created() {
    this.getData();
  },
  methods: {
    belongsToBrazil() {
      var errors = [];
      if (this.user.country == this.brazilID && !this.user.state) {
        errors.push(this.$t('rules.required'));
      }
      return errors;
    },
    async getData() {
      await this.$store.dispatch('country/loadList', { has_masters: true });
      this.brazilID = this.$store.state.country.list.items.find(
        country => country.name.en == 'BRAZIL'
      )._id;
      if (this.user.country) {
        await this.getStates();
      }
      if (this.user.country) {
        await this.getCities();
      }
    },
    async getStates() {
      this.states = [];
      this.cities = [];
      const result = await this.$store.dispatch(
        'country/listStates',
        this.user.country
      );
      if (result.error != true) {
        this.states = result;
      }
    },
    async getCities() {
      this.cities = [];
      const payload = {
        _id: this.user.country,
        state: this.user.state,
      };
      const result = await this.$store.dispatch('country/listCities', payload);
      if (result.error != true && result.length != 0) {
        this.cities = result;
      }
    },
    async setDialCode() {
      const result = await this.$store.dispatch(
        'country/getDialCode',
        this.user.country
      );
      if (typeof result != 'undefined') {
        this.user.dialCode = result.dialCode;
        this.hasDialCode = true;
      } else {
        this.hasDialCode = false;
        this.user.dialCode = '';
      }
    },
  },
};
</script>
