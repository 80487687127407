<template>
  <div>
    <lms-default-loader v-if="!userDataLoaded" />
    <div v-if="missingData" class="missing-data">
      {{ $t('users.edit.alertMissing') }}
      <br />
      <br />
    </div>
    <v-card elevation="0" v-if="userDataLoaded">
      <v-card-text>
        <v-form ref="form">
          {{ $t('labels.profilePicture') }}
          <f-group-container>
            <picture-form />
          </f-group-container>

          {{ $t('labels.personalData') }}
          <f-group-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  name="user-edit-flukeID"
                  v-model="user.flukeAcademyID"
                  :label="$tt.capitalize($t('labels.flukeAcademyID'))"
                  disabled
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  name="user-edit-name"
                  v-model="user.name"
                  :label="$tt.capitalize($t('labels.name'))"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  name="user-edit-email"
                  v-model="user.email"
                  :label="$tt.capitalize($t('labels.email'))"
                  disabled
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  outlined
                  name="user-edit-ddd"
                  :disabled="true"
                  v-model="user.dialCode"
                  :label="$tt.capitalize($t('labels.dialCode'))"
                />
              </v-col>
              <v-col cols="9">
                <v-text-field
                  outlined
                  name="user-edit-phone"
                  v-mask="'##############'"
                  v-model="user.phone"
                  :label="$tt.capitalize($t('labels.phone'))"
                />
              </v-col>
            </v-row>
          </f-group-container>

          {{ $t('labels.locationData') }}
          <f-group-container>
            <location-data-form />
          </f-group-container>

          {{ $t('labels.masterDistributor') }}
          <f-group-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  name="user-edit-master"
                  v-model="master"
                  :label="$tt.capitalize($t('labels.master'))"
                  disabled
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  name="user-edit-distributor"
                  v-model="user.distributor"
                  :label="$tt.capitalize($t('labels.distributor'))"
                />
              </v-col>
            </v-row>
          </f-group-container>
        </v-form>
      </v-card-text>
    </v-card>

    <lms-btn-holder>
      <v-btn
        color="primary"
        large
        elevation="0"
        @click="save"
        :disabled="false"
        :loading="false"
      >
        {{ $t('labels.save') }}
      </v-btn>

      <v-btn large depressed @click="back">
        {{ $t('labels.cancel') }}
      </v-btn>
    </lms-btn-holder>
  </div>
</template>

<script>
import LocationDataForm from './LocationDataForm.vue';
import PictureForm from './PictureForm.vue';
import FGroupContainer from '../shared/GroupContainer.vue';

export default {
  components: {
    LocationDataForm,
    FGroupContainer,
    PictureForm,
  },
  data: () => ({
    userDataLoaded: false,
    missingData: false,
  }),
  computed: {
    user() {
      return this.$store.getters['user/current'];
    },
    master() {
      try {
        return this.user.masters[0].title;
      } catch (e) {
        return '';
      }
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.$store.state.user.current._id = this.$store.getters['auth/user'].id;
      let user = await this.$store.dispatch('user/getData', [
        'name',
        'masters',
        'distributor',
        'phone',
        'country',
        'email',
        'flukeAcademyID',
        'avatar',
        'dialCode',
        'state',
        'city',
      ]);
      this.$store.commit('user/setCurrent', user);
      this.userDataLoaded = true;
      this.$store.commit('system/loading', false);

      // this.$refs.form.validate();
      if (
        !this.user.country ||
        !this.user.state ||
        !this.user.city ||
        !this.user.dialCode
      ) {
        this.missingData = true;
        setTimeout(() => {
          this.$refs.form.validate();
        }, 500);
      }
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const data = {
        ...this.user,
        masters: this.user.masters.map(master => master._id),
        profile: true,
      };
      if (typeof data.avatar === 'string') {
        delete data.avatar;
      }
      await this.$store.dispatch('user/update', data);

      this.$store.commit('snackbar/show', {
        content: this.$t('labels.editedWithSuccess'),
        type: 'success',
      });
      this.back();
    },
    async back() {
      this.$router.push({
        name: 'profile-view',
      });
    },
  },
};
</script>

<style lang="css" scoped>
.missing-data {
  color: red;
  padding-left: 16px;
}
</style>
