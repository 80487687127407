import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"4"}},[_c(VSelect,{attrs:{"name":"user-country","item-value":"_id","item-text":"name","items":_vm.countriesList,"label":_vm.$tt.capitalize(_vm.$t('labels.country')),"rules":_vm.valid,"required":"","outlined":"","disabled":_vm.isLoading},on:{"change":function($event){_vm.getStates();
        _vm.setDialCode();}},model:{value:(_vm.user.country),callback:function ($$v) {_vm.$set(_vm.user, "country", $$v)},expression:"user.country"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"4"}},[_c(VSelect,{attrs:{"name":"user-state","items":_vm.states,"label":_vm.$tt.capitalize(_vm.$t('labels.states')),"rules":_vm.belongsToBrazil(),"outlined":"","disabled":_vm.isLoading},on:{"change":_vm.getCities},model:{value:(_vm.user.state),callback:function ($$v) {_vm.$set(_vm.user, "state", $$v)},expression:"user.state"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"4"}},[_c(VAutocomplete,{attrs:{"outlined":"","name":"user-city","disabled":_vm.isLoading,"items":_vm.cities,"label":_vm.$tt.capitalize(_vm.$t('labels.city'))},model:{value:(_vm.user.city),callback:function ($$v) {_vm.$set(_vm.user, "city", $$v)},expression:"user.city"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }