export default {
  email(required, invalid) {
    return [
      value => !!value || required,
      value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || invalid
      },
    ]
  },
  required(required) {
    return [
      value => !!value || required
    ]
  },
  confirmPassword(reference, required, mustMatch) {
    return [
      value => !!value || required,
      value => value == reference || mustMatch,
    ]
  },
  checkLangData(data, langs) {
    var isValid = true
    langs.forEach(lang => {
      if (
        typeof (data[lang]) == 'undefined' ||
        data[lang] == ''
      ) {
        isValid = false
      }
    })
    return isValid
  }
}
