const fileUploader = {
  async fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', ({ target }) => {
        if (target.error) {
          reject(target.error);
        } else {
          resolve(target.result);
        }
      });
      reader.readAsDataURL(file);
    });
  },
  download({ name, type, data, isBase64 }) {
    let blob = new Blob([data], { type: 'application/pdf' });
    if (isBase64) {
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      blob = new Blob([byteArray], { type });
    }

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, name);
    } else {
      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(blob, { type });
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  },
  getMimeTypeByExtension(extension) {
    const values = {
      default: 'application/octet-stream',
      jpg: 'image/jpeg',
      png: 'image/png',
      gif: 'image/gif',
    };
    return values[extension] || values.default;
  },
};

export default fileUploader;
